import React, { createContext, useContext, useMemo } from 'react';
import useAuth from '../hooks/useAuth';

const AuthContext = createContext(null);

export const AuthProvider = React.memo(({ children }) => {
    const {
        user,
        loading,
        isGuest,
        login,
        loginWithGoogle,
        logout,
        refetchUser
    } = useAuth();

    const authState = useMemo(() => ({
        user,
        loading,
        isGuest
    }), [user, loading, isGuest]);

    const authActions = useMemo(() => ({
        login,
        loginWithGoogle,
        logout,
        refetchUser
    }), [login, loginWithGoogle, logout, refetchUser]);

    const value = useMemo(() => ({
        ...authState,
        ...authActions
    }), [authState, authActions]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
});

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuthContext must be used within an AuthProvider');
    }
    return context;
};