import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    BottomNavigation as MuiBottomNavigation,
    BottomNavigationAction,
    Paper,
} from '@mui/material';
import {
    Home as HomeIcon,
    ViewInAr as VisualizeIcon,
    SettingsInputComponent as PortMappingIcon,
    BugReport as ReportIcon,
} from '@mui/icons-material';

const BottomNavigation = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleChange = (event, newValue) => {
        navigate(newValue);
    };

    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1000,
            }}
            elevation={3}
        >
            <MuiBottomNavigation
                value={location.pathname}
                onChange={handleChange}
                showLabels
            >
                <BottomNavigationAction label="Home" value="/" icon={<HomeIcon />} />
                <BottomNavigationAction
                    label="Visualize"
                    value="/visualize"
                    icon={<VisualizeIcon />}
                />
                <BottomNavigationAction
                    label="Port Mapping"
                    value="/portmapping"
                    icon={<PortMappingIcon />}
                />
                <BottomNavigationAction label="Report" value="/report" icon={<ReportIcon />} />
            </MuiBottomNavigation>
        </Paper>
    );
};

export default BottomNavigation;