import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { Link as MuiLink } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { resendVerificationEmail } from '../../utils/api';

const LoginForm = ({ onLogin, onToggleForm, onForgotPassword }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showResendVerification, setShowResendVerification] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await onLogin(email, password);
        } catch (err) {
            if (
                err.response &&
                err.response.status === 403 &&
                err.response.data.error === 'Please verify your email before logging in'
            ) {
                setError('Please verify your email before logging in.');
                setShowResendVerification(true);
            } else {
                setError('Invalid email or password');
                setShowResendVerification(false);
            }
        }
    };

    const handleResendVerification = async () => {
        try {
            await resendVerificationEmail(email);
            setError('Verification email resent. Please check your inbox.');
            setShowResendVerification(false);
        } catch (err) {
            setError('Failed to resend verification email. Please try again.');
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Sign In
            </Button>
            <Button
                fullWidth
                variant="outlined"
                startIcon={<GoogleIcon />}
                onClick={() => (window.location.href = '/auth/google')}
                sx={{ mb: 2 }}
            >
                Sign in with Google
            </Button>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <MuiLink component="button" variant="body2" onClick={onToggleForm}>
                    {"Don't have an account? Sign Up"}
                </MuiLink>
                <MuiLink component="button" variant="body2" onClick={onForgotPassword}>
                    Forgot password?
                </MuiLink>
            </Box>
            {error && (
                <Typography color="error" align="center" sx={{ mt: 2 }}>
                    {error}
                </Typography>
            )}
            {showResendVerification && (
                <Button
                    variant="text"
                    color="primary"
                    onClick={handleResendVerification}
                    sx={{ mt: 1 }}
                    fullWidth
                >
                    Resend verification email
                </Button>
            )}
        </Box>
    );
};

export default LoginForm;