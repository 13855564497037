import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LayoutProvider } from './components/LayoutContext';
import ErrorBoundary from './components/ErrorBoundary';
import AppContent from './components/AppContent';
import useTheme from './hooks/useTheme';
import { AuthProvider } from './context/AuthContext';
import { Helmet } from 'react-helmet';

function App() {
    const { theme, darkMode, toggleDarkMode } = useTheme();

    return (
        <ErrorBoundary>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Helmet>
                    <meta
                        http-equiv="Content-Security-Policy"
                        content="default-src 'self'; connect-src 'self' https://*.google.com https://*.googleapis.com; script-src 'self' https://*.google.com https://*.googleapis.com; frame-src https://*.google.com; img-src 'self' data: https://*.google.com https://*.googleapis.com; style-src 'self' 'unsafe-inline' https://*.google.com https://*.googleapis.com;"
                    />
                </Helmet>
                <AuthProvider>
                    <Router>
                        <LayoutProvider>
                            <AppContent darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
                        </LayoutProvider>
                    </Router>
                </AuthProvider>
            </ThemeProvider>
        </ErrorBoundary>
    );
}

export default App;