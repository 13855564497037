import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import axios from 'axios';
import { logInfo, logError, logWarning } from '../utils/logger';
import debounce from 'lodash/debounce';

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
const RETRY_DELAY = 5000; // 5 seconds
const MAX_RETRIES = 3;

const useAuth = () => {
    const [loading, setLoading] = useState(true);
    const [isGuest, setIsGuest] = useState(true);
    const userRef = useRef(null);
    const lastFetchTimeRef = useRef(0);
    const fetchInProgressRef = useRef(false);
    const retryCountRef = useRef(0);

    const fetchUser = useCallback(
        debounce(async (force = false) => {
            if (fetchInProgressRef.current && !force) {
                logInfo('Fetch already in progress, skipping');
                return;
            }

            const token = localStorage.getItem('token');
            const now = Date.now();

            if (!force && userRef.current && now - lastFetchTimeRef.current < CACHE_DURATION) {
                logInfo('Using cached user data');
                setLoading(false);
                return;
            }

            fetchInProgressRef.current = true;

            if (token) {
                try {
                    setLoading(true);
                    logInfo('Fetching user data');
                    const response = await axios.get('/api/user', {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    userRef.current = response.data;
                    setIsGuest(false);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    lastFetchTimeRef.current = now;
                    retryCountRef.current = 0;
                    logInfo('User data fetched successfully');
                } catch (error) {
                    logError('Failed to fetch user data:', error);
                    if (error.response && error.response.status === 401) {
                        localStorage.removeItem('token');
                        delete axios.defaults.headers.common['Authorization'];
                        setIsGuest(true);
                        userRef.current = null;
                    } else if (retryCountRef.current < MAX_RETRIES) {
                        retryCountRef.current++;
                        logWarning(`Retrying user data fetch (Attempt ${retryCountRef.current})`);
                        setTimeout(() => fetchUser(force), RETRY_DELAY);
                        return;
                    } else {
                        logError('Max retries reached, setting as guest');
                        setIsGuest(true);
                        userRef.current = null;
                    }
                } finally {
                    if (retryCountRef.current >= MAX_RETRIES || retryCountRef.current === 0) {
                        setLoading(false);
                        fetchInProgressRef.current = false;
                    }
                }
            } else {
                logInfo('No token found, setting as guest');
                setIsGuest(true);
                userRef.current = null;
                setLoading(false);
                fetchInProgressRef.current = false;
            }
        }, 300),
        []
    );

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    const login = useCallback(async (email, password) => {
        try {
            logInfo('Attempting login');
            const response = await axios.post('/auth/login', { email, password });
            const { user: userData, token } = response.data;
            userRef.current = userData;
            setIsGuest(false);
            localStorage.setItem('token', token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            lastFetchTimeRef.current = Date.now();
            logInfo('User logged in successfully');
            await fetchUser(true);
            return { success: true };
        } catch (error) {
            logError('Login failed:', error);
            return { success: false, error: error.response?.data?.error || 'An error occurred during login' };
        }
    }, [fetchUser]);

    const loginWithGoogle = useCallback(async () => {
        try {
            logInfo('Attempting Google login');
            const response = await axios.get('/auth/google');
            const { url } = response.data;

            const popup = window.open(url, 'Google Login', 'width=500,height=600');

            if (!popup) {
                throw new Error('Failed to open Google login popup. Please allow popups for this site.');
            }

            return new Promise((resolve, reject) => {
                window.addEventListener('message', async (event) => {
                    if (event.origin !== window.location.origin) return;

                    if (event.data.type === 'GOOGLE_LOGIN_SUCCESS') {
                        const { token, user: userData } = event.data;
                        userRef.current = userData;
                        setIsGuest(false);
                        localStorage.setItem('token', token);
                        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                        lastFetchTimeRef.current = Date.now();
                        logInfo('User logged in with Google successfully');
                        await fetchUser(true);
                        resolve({ success: true });
                    } else if (event.data.type === 'GOOGLE_LOGIN_FAILURE') {
                        logError('Google login failed:', event.data.error);
                        reject({ success: false, error: event.data.error || 'An error occurred during Google login' });
                    }
                }, { once: true });
            });
        } catch (error) {
            logError('Google login failed:', error);
            return { success: false, error: error.message || 'An error occurred during Google login' };
        }
    }, [fetchUser]);

    const logout = useCallback(() => {
        logInfo('Logging out user');
        userRef.current = null;
        setIsGuest(true);
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
        lastFetchTimeRef.current = 0;
        logInfo('User logged out successfully');
        fetchUser(true);
    }, [fetchUser]);

    const refetchUser = useCallback(() => {
        logInfo('Forcing user data refetch');
        return fetchUser(true);
    }, [fetchUser]);

    const authState = useMemo(() => ({
        user: userRef.current,
        loading,
        isGuest,
    }), [loading, isGuest]);

    const authActions = useMemo(() => ({
        login,
        loginWithGoogle,
        logout,
        refetchUser
    }), [login, loginWithGoogle, logout, refetchUser]);

    return { ...authState, ...authActions };
};

export default useAuth;