import React, { useState, useCallback, useEffect, Suspense, lazy, useMemo } from 'react';
import { Routes, Route, useLocation, useNavigate, Link } from 'react-router-dom';
import { Box, Container, Snackbar, useMediaQuery, useTheme, CircularProgress, Grid, Button, Typography, Paper } from '@mui/material';
import { Alert } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { Visibility, Share, Clear } from '@mui/icons-material';
import axios from 'axios';

import AppBar from './Navigation/AppBar';
import MobileMenu from './Navigation/MobileMenu';
import BottomNavigation from './Navigation/BottomNavigation';
import AuthDialog from './Auth/AuthDialog';
import UserLayouts from './UserLayouts';
import FileUpload from './FileUpload';
import ConfirmDialog from './Common/ConfirmDialog';
import NoLayoutMessage from './Common/NoLayoutMessage';

import { createErrorHandler, AppError } from '../utils/errorHandler';
import { logInfo, logError } from '../utils/logger';
import { useAuthContext } from '../context/AuthContext';
import useLayoutManager from '../hooks/useLayoutManager';

// Lazy-loaded components
const Visualizer = lazy(() => import('./visualizer'));
const ControllerPortMapping = lazy(() => import('./ControllerPortMapping'));
const BugReportForm = lazy(() => import('./BugReportForm'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./TermsAndConditions'));
const SharedLayout = lazy(() => import('./SharedLayout'));
const VerifyEmail = lazy(() => import('./VerifyEmail'));
const ForgotPassword = lazy(() => import('./ForgotPassword'));
const ResetPassword = lazy(() => import('./ResetPassword'));
const CircleModelTest = lazy(() => import('./CircleModelTest'));

const AppContent = React.memo(({ darkMode, toggleDarkMode }) => {
    const { user, loading: authLoading, isGuest, login, loginWithGoogle, logout, refetchUser } = useAuthContext();
    const { currentLayout, isLoading: layoutLoading, layoutId, shareId, selectLayout, fetchLayouts, shareLayout, clearLayout } = useLayoutManager();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [confirmDialog, setConfirmDialog] = useState({ open: false, title: '', message: '' });
    const [authDialogOpen, setAuthDialogOpen] = useState(false);
    const [isLoginForm, setIsLoginForm] = useState(true);
    const [authError, setAuthError] = useState(null);
    const [isSharing, setIsSharing] = useState(false);
    const [userLayouts, setUserLayouts] = useState([]);
    const [isLoadingLayouts, setIsLoadingLayouts] = useState(false);
    const [editingLayout, setEditingLayout] = useState(null);
    const [editingName, setEditingName] = useState('');

    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleError = useMemo(() => createErrorHandler(setSnackbar), []);

    const fetchUserLayouts = useCallback(async () => {
        if (!user) return;
        setIsLoadingLayouts(true);
        try {
            const response = await fetchLayouts();
            setUserLayouts(response);
        } catch (error) {
            console.error('Error fetching user layouts:', error);
            handleError(error);
        } finally {
            setIsLoadingLayouts(false);
        }
    }, [user, fetchLayouts, handleError]);

    useEffect(() => {
        if (user) {
            fetchUserLayouts();
        }
    }, [user, fetchUserLayouts]);

    const handleEditLayout = useCallback((layout) => {
        setEditingLayout(layout);
        setEditingName(layout.name);
        logInfo(`Editing layout: ${layout.name}`);
    }, []);

    const handleClearConfirmation = useCallback(() => {
        setConfirmDialog({
            open: true,
            title: 'Clear Configuration',
            message: 'Are you sure you want to clear out this config?',
        });
    }, []);

    const handleSaveLayoutName = useCallback(async () => {
        if (!editingLayout) return;

        try {
            await axios.put(`/api/layout/${editingLayout._id}/name`, {
                name: editingName,
            });
            setEditingLayout(null);
            // Update the local state with the new name
            setUserLayouts((prevLayouts) =>
                prevLayouts.map((layout) =>
                    layout._id === editingLayout._id ? { ...layout, name: editingName } : layout
                )
            );
            logInfo('Layout name updated successfully');
            setSnackbar({
                open: true,
                message: 'Layout name updated successfully',
                severity: 'success',
            });
        } catch (error) {
            handleError(new AppError('Failed to update layout name. Please try again.', 500));
        }
    }, [editingLayout, editingName, handleError]);

    const handleClearConfirmed = useCallback(() => {
        logInfo('Clearing layout...');
        if (typeof clearLayout !== 'function') {
            logError('clearLayout is not a function', clearLayout);
            handleError(new Error('Unable to clear layout. Please try again later.'));
            return;
        }
        clearLayout()
            .then(() => {
                logInfo('Layout cleared successfully');
                setSnackbar({
                    open: true,
                    message: 'Visualizer cleared successfully!',
                    severity: 'success',
                });
                setConfirmDialog((prevDialog) => ({ ...prevDialog, open: false }));
                navigate('/', { replace: true });
            })
            .catch((error) => {
                logError('Error clearing layout:', error);
                handleError(error);
            });
    }, [navigate, clearLayout, handleError]);

    const handleShare = useCallback(() => {
        if (!currentLayout) {
            setSnackbar({
                open: true,
                message: 'No layout to share. Please upload files first.',
                severity: 'warning',
            });
            return;
        }

        setIsSharing(true);
        if (shareId) {
            const url = `${window.location.origin}/share/${shareId}`;
            navigator.clipboard.writeText(url)
                .then(() => {
                    setSnackbar({
                        open: true,
                        message: 'Share URL copied to clipboard!',
                        severity: 'success',
                    });
                })
                .catch(handleError)
                .finally(() => setIsSharing(false));
        } else if (layoutId) {
            shareLayout(layoutId)
                .then((newShareId) => {
                    const url = `${window.location.origin}/share/${newShareId}`;
                    return navigator.clipboard.writeText(url);
                })
                .then(() => {
                    setSnackbar({
                        open: true,
                        message: 'Share URL copied to clipboard!',
                        severity: 'success',
                    });
                })
                .catch(handleError)
                .finally(() => setIsSharing(false));
        }
    }, [currentLayout, shareId, layoutId, shareLayout, handleError]);

    const handleUploadSuccess = useCallback(
        async (response) => {
            selectLayout(response.id)
                .then(() => {
                    setSnackbar({
                        open: true,
                        message: 'Files uploaded successfully!',
                        severity: 'success',
                    });
                    fetchUserLayouts();
                })
                .catch(handleError);
        },
        [selectLayout, fetchUserLayouts, handleError]
    );

    const handleLogin = useCallback(async (email, password) => {
        try {
            const result = await login(email, password);
            if (result.success) {
                setAuthError(null);
                setAuthDialogOpen(false);
                await refetchUser();
                setSnackbar({
                    open: true,
                    message: 'Logged in successfully!',
                    severity: 'success',
                });
            } else {
                setAuthError(result.error);
            }
        } catch (error) {
            logError('Login error:', error);
            handleError(error);
        }
    }, [login, refetchUser, handleError]);

    const handleLogout = useCallback(() => {
        logout();
        selectLayout(null)
            .then(() => {
                navigate('/');
                setSnackbar({
                    open: true,
                    message: 'Logged out successfully!',
                    severity: 'success',
                });
            })
            .catch(handleError);
    }, [logout, selectLayout, navigate, handleError]);

    if (authLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box bgcolor="background.default" minHeight="100vh" display="flex" flexDirection="column">
            <AppBar
                user={user}
                isGuest={isGuest}
                onMenuToggle={() => setMobileMenuOpen(true)}
                onLogout={handleLogout}
                onLogin={() => setAuthDialogOpen(true)}
                darkMode={darkMode}
                onToggleDarkMode={toggleDarkMode}
            />
            <MobileMenu open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} />
            <Container maxWidth={false} sx={{ mt: 8, mb: 4, flex: 1 }}>
                <AnimatePresence mode="wait">
                    <motion.div
                        key={location.pathname}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <Suspense fallback={<CircularProgress />}>
                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        <Grid container spacing={3}>
                                            {user && (
                                                <Grid item xs={12}>
                                                    <Paper elevation={0}>
                                                        <Box p={2}>
                                                            <Typography variant="h5" gutterBottom>
                                                                Your Saved Layouts
                                                            </Typography>
                                                            {isLoadingLayouts ? (
                                                                <Box display="flex" justifyContent="center" p={2}>
                                                                    <CircularProgress />
                                                                </Box>
                                                            ) : (
                                                                <UserLayouts
                                                                    layouts={userLayouts}
                                                                    onLayoutSelect={selectLayout}
                                                                    onLayoutsChange={fetchUserLayouts}
                                                                    editingLayout={editingLayout}
                                                                    editingName={editingName}
                                                                    onEditLayout={handleEditLayout}
                                                                    onSaveLayoutName={handleSaveLayoutName}
                                                                    onEditNameChange={setEditingName}
                                                                />
                                                            )}
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} md={6}>
                                                <Paper elevation={0}>
                                                    <Box p={2}>
                                                        <Typography variant="h5" gutterBottom>
                                                            Upload Files
                                                        </Typography>
                                                        <FileUpload
                                                            onUploadSuccess={handleUploadSuccess}
                                                            user={user ? { ...user, layouts: userLayouts } : null}
                                                        />
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                            {currentLayout && (
                                                <Grid item xs={12} md={6}>
                                                    <Paper elevation={0}>
                                                        <Box p={2}>
                                                            <Typography variant="h5" gutterBottom>
                                                                Visualization
                                                            </Typography>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        startIcon={<Visibility />}
                                                                        component={Link}
                                                                        to="/visualize"
                                                                        fullWidth
                                                                    >
                                                                        xLights Layout
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        startIcon={isSharing ? <CircularProgress size={24} color="inherit" /> : <Share />}
                                                                        onClick={handleShare}
                                                                        disabled={isSharing || layoutLoading}
                                                                        fullWidth
                                                                    >
                                                                        {isSharing ? 'Sharing...' : 'Share Layout'}
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        startIcon={<Clear />}
                                                                        onClick={handleClearConfirmation}
                                                                        fullWidth
                                                                    >
                                                                        Clear Layout
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            )}
                                        </Grid>
                                    }
                                />
                                <Route
                                    path="/visualize"
                                    element={
                                        currentLayout ? (
                                            <Visualizer
                                                layout={currentLayout}
                                                isSharedLayout={false}
                                                isLoading={layoutLoading}
                                            />
                                        ) : (
                                            <NoLayoutMessage />
                                        )
                                    }
                                />
                                <Route
                                    path="/portmapping"
                                    element={currentLayout ? <ControllerPortMapping layout={currentLayout} /> : <NoLayoutMessage />}
                                />
                                <Route path="/report" element={<BugReportForm />} />
                                <Route path="/share/:shareId/*" element={<SharedLayout />} />
                                <Route path="/terms" element={<TermsAndConditions />} />
                                <Route path="/privacy" element={<PrivacyPolicy />} />
                                <Route path="/forgot-password" element={<ForgotPassword />} />
                                <Route path="/reset-password/:token" element={<ResetPassword />} />
                                <Route path="/verify-email/:token" element={<VerifyEmail />} />
                                <Route path="/circletest" element={<CircleModelTest />} />
                            </Routes>
                        </Suspense>
                    </motion.div>
                </AnimatePresence>
            </Container>
            {isMobile && <BottomNavigation />}
            <AuthDialog
                open={authDialogOpen}
                onClose={() => setAuthDialogOpen(false)}
                isLoginForm={isLoginForm}
                setIsLoginForm={setIsLoginForm}
                onLogin={handleLogin}
                onGoogleLogin={loginWithGoogle}
                onRegister={() => { }} // Implement register function if needed
                authError={authError}
                onForgotPassword={() => navigate('/forgot-password')}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <ConfirmDialog
                open={confirmDialog.open}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({ ...confirmDialog, open: false })}
                onConfirm={handleClearConfirmed}
            />
        </Box>
    );
});

export default AppContent;