import { useState, useEffect, useMemo } from 'react';
import { createTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const useTheme = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [darkMode, setDarkMode] = useState(() => {
        const savedMode = localStorage.getItem('darkMode');
        return savedMode !== null ? JSON.parse(savedMode) : prefersDarkMode;
    });

    useEffect(() => {
        const handleSystemPreferenceChange = (e) => {
            if (!localStorage.getItem('darkMode')) {
                setDarkMode(e.matches);
            }
        };
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        darkModeMediaQuery.addListener(handleSystemPreferenceChange);
        return () => darkModeMediaQuery.removeListener(handleSystemPreferenceChange);
    }, []);

    const toggleDarkMode = () => {
        setDarkMode((prevMode) => {
            const newMode = !prevMode;
            localStorage.setItem('darkMode', JSON.stringify(newMode));
            return newMode;
        });
    };

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: darkMode ? 'dark' : 'light',
                    primary: {
                        main: '#3f51b5',
                    },
                    secondary: {
                        main: '#f50057',
                    },
                    background: {
                        default: darkMode ? '#121212' : '#ffffff',
                        paper: darkMode ? '#1e1e1e' : '#ffffff',
                    },
                    text: {
                        primary: darkMode ? '#ffffff' : '#000000',
                        secondary: darkMode ? '#b0b0b0' : '#757575',
                    },
                    link: {
                        main: darkMode ? '#90caf9' : '#3f51b5',
                    },
                },
                components: {
                    MuiCssBaseline: {
                        styleOverrides: {
                            body: {
                                backgroundColor: darkMode ? '#121212' : '#ffffff',
                                margin: 0,
                                padding: 0,
                            },
                        },
                    },
                    MuiPaper: {
                        styleOverrides: {
                            root: {
                                backgroundColor: darkMode ? '#1e1e1e' : '#ffffff',
                            },
                        },
                    },
                    MuiTextField: {
                        styleOverrides: {
                            root: {
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: darkMode ? '#b0b0b0' : 'rgba(0, 0, 0, 0.23)',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: darkMode ? '#ffffff' : 'rgba(0, 0, 0, 0.87)',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#3f51b5',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: darkMode ? '#b0b0b0' : 'rgba(0, 0, 0, 0.6)',
                                },
                                '& .MuiInputBase-input': {
                                    color: darkMode ? '#ffffff' : 'rgba(0, 0, 0, 0.87)',
                                },
                            },
                        },
                    },
                    MuiButton: {
                        styleOverrides: {
                            root: {
                                '&.MuiButton-containedPrimary': {
                                    backgroundColor: '#3f51b5',
                                    color: '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#303f9f',
                                    },
                                },
                                '&.MuiButton-outlinedPrimary': {
                                    borderColor: darkMode ? '#ffffff' : '#3f51b5',
                                    color: darkMode ? '#ffffff' : '#3f51b5',
                                    '&:hover': {
                                        borderColor: darkMode ? '#b0b0b0' : '#303f9f',
                                        backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(63, 81, 181, 0.08)',
                                    },
                                },
                            },
                        },
                    },
                    MuiLink: {
                        styleOverrides: {
                            root: {
                                color: darkMode ? '#90caf9' : '#3f51b5',
                                textDecoration: 'none',
                                '&:hover': {
                                    color: darkMode ? '#bbdefb' : '#303f9f',
                                    textDecoration: 'underline',
                                },
                                '&.auth-toggle-link': {
                                    color: darkMode ? '#90caf9' : '#3f51b5',
                                    '&:hover': {
                                        color: darkMode ? '#bbdefb' : '#303f9f',
                                    },
                                },
                                '&[color="textSecondary"]': {
                                    color: darkMode ? '#ffffff' : 'inherit',
                                    '&:hover': {
                                        color: darkMode ? '#bbdefb' : '#303f9f',
                                    },
                                },
                            },
                        },
                    },
                    MuiAppBar: {
                        styleOverrides: {
                            root: {
                                backgroundColor: darkMode ? '#121212' : '#ffffff',
                                color: darkMode ? '#ffffff' : '#000000',
                                boxShadow: 'none',
                                borderBottom: `1px solid ${darkMode ? '#ffffff20' : '#00000020'}`,
                            },
                        },
                    },
                    MuiDrawer: {
                        styleOverrides: {
                            paper: {
                                backgroundColor: darkMode ? '#121212' : '#ffffff',
                            },
                        },
                    },
                    MuiListItem: {
                        styleOverrides: {
                            root: {
                                '&:hover': {
                                    backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
                                },
                            },
                        },
                    },
                    MuiBottomNavigation: {
                        styleOverrides: {
                            root: {
                                backgroundColor: darkMode ? '#1e1e1e' : '#ffffff',
                            },
                        },
                    },
                    MuiBottomNavigationAction: {
                        styleOverrides: {
                            root: {
                                color: darkMode ? '#b0b0b0' : '#757575',
                                '&.Mui-selected': {
                                    color: '#3f51b5',
                                },
                            },
                        },
                    },
                },
            }),
        [darkMode]
    );

    return { theme, darkMode, toggleDarkMode };
};

export default useTheme;