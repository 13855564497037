import { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import axios from 'axios';
import { logInfo, logWarning, logError } from '../utils/logger';
import { useAuthContext } from '../context/AuthContext';

const LAYOUT_CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
const RETRY_DELAY = 5000; // 5 seconds
const MAX_RETRIES = 3;

const useLayoutManager = () => {
    const { user, isGuest } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [layoutId, setLayoutId] = useState(null);
    const [shareId, setShareId] = useState(null);
    const [layouts, setLayouts] = useState([]);

    const layoutRef = useRef(null);
    const lastLayoutsFetchTimeRef = useRef(0);
    const lastLayoutFetchTimeRef = useRef(0);
    const fetchInProgressRef = useRef(false);
    const retryCountRef = useRef(0);

    const fetchLayouts = useCallback(async (force = false) => {
        if (fetchInProgressRef.current && !force) {
            return layouts;
        }

        const now = Date.now();
        if (!force && layouts.length > 0 && now - lastLayoutsFetchTimeRef.current < LAYOUT_CACHE_DURATION) {
            return layouts;
        }

        if (!user && !isGuest) {
            logInfo('User not authenticated, skipping layout fetch');
            return [];
        }

        fetchInProgressRef.current = true;
        setIsLoading(true);
        retryCountRef.current = 0;

        try {
            const endpoint = isGuest ? '/api/guest/layouts' : '/api/user/layouts';
            logInfo('Attempting to fetch layouts');
            const response = await axios.get(endpoint);
            const fetchedLayouts = Array.isArray(response.data) ? response.data : [];
            setLayouts(fetchedLayouts);
            lastLayoutsFetchTimeRef.current = now;
            logInfo(`Layouts fetched successfully. Count: ${fetchedLayouts.length}`);
            return fetchedLayouts;
        } catch (error) {
            logError('Error fetching layouts:', error);
            if (error.response && error.response.status === 429 && retryCountRef.current < MAX_RETRIES) {
                logWarning(`Rate limit reached, retrying in ${RETRY_DELAY / 1000} seconds`);
                retryCountRef.current++;
                await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
                return fetchLayouts(force);
            }
            throw error;
        } finally {
            setIsLoading(false);
            fetchInProgressRef.current = false;
        }
    }, [user, isGuest]);

    const fetchLayout = useCallback(async (id, force = false) => {
        if (!id) {
            logInfo('No layout ID provided, skipping fetch');
            return null;
        }

        const now = Date.now();
        if (!force && layoutRef.current && layoutRef.current._id === id && now - lastLayoutFetchTimeRef.current < LAYOUT_CACHE_DURATION) {
            return layoutRef.current;
        }

        setIsLoading(true);
        try {
            logInfo(`Attempting to fetch layout: ${id}, force: ${force}`);
            const response = await axios.get(`/api/layout/${id}`);
            layoutRef.current = response.data;
            setLayoutId(id);
            setShareId(response.data.shareId);
            lastLayoutFetchTimeRef.current = now;
            logInfo(`Layout fetched successfully: ${id}`);
            return layoutRef.current;
        } catch (error) {
            logError(`Error fetching layout: ${error.message}`);
            if (error.response && error.response.status === 429) {
                logWarning('Rate limit reached when fetching layout');
            }
            layoutRef.current = null;
            setLayoutId(null);
            setShareId(null);
            localStorage.removeItem('layoutId');
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, []);

    const selectLayout = useCallback(async (id) => {
        if (!id) {
            logInfo('No layout ID provided, clearing current layout');
            layoutRef.current = null;
            setLayoutId(null);
            setShareId(null);
            return null;
        }
        logInfo(`Layout selected: ${id}`);
        setLayoutId(id);
        return await fetchLayout(id, true);
    }, [fetchLayout]);

    const updateLayoutName = useCallback(async (layoutId, newName) => {
        logInfo(`Updating layout name: ${layoutId}`);
        try {
            await axios.put(`/api/layout/${layoutId}/name`, { name: newName });
            logInfo('Layout name updated successfully');

            if (layoutRef.current && layoutRef.current._id === layoutId) {
                layoutRef.current.name = newName;
            }

            setLayouts(prevLayouts => prevLayouts.map(layout =>
                layout._id === layoutId ? { ...layout, name: newName } : layout
            ));
        } catch (error) {
            logError('Error updating layout name:', error);
            throw error;
        }
    }, []);

    const clearLayout = useCallback(async () => {
        logInfo('Clearing current layout');
        try {
            layoutRef.current = null;
            setLayoutId(null);
            setShareId(null);
            localStorage.removeItem('layoutId');
            logInfo('Layout cleared successfully');
        } catch (error) {
            logError('Error clearing layout:', error);
            throw error;
        }
    }, []);

    const shareLayout = useCallback(async (id) => {
        logInfo(`Sharing layout: ${id}`);
        try {
            const response = await axios.post(`/api/layout/${id}/share`);
            const newShareId = response.data.shareId;
            setShareId(newShareId);
            logInfo(`Layout shared successfully. Share ID: ${newShareId}`);
            return newShareId;
        } catch (error) {
            logError('Error sharing layout:', error);
            throw error;
        }
    }, []);

    useEffect(() => {
        const savedLayoutId = localStorage.getItem('layoutId');
        if (savedLayoutId && !layoutRef.current && (user || isGuest)) {
            fetchLayout(savedLayoutId);
        }
    }, [fetchLayout, user, isGuest]);

    return useMemo(() => ({
        layouts,
        currentLayout: layoutRef.current,
        isLoading,
        layoutId,
        shareId,
        fetchLayouts,
        fetchLayout,
        selectLayout,
        updateLayoutName,
        clearLayout,
        shareLayout
    }), [layouts, isLoading, layoutId, shareId, fetchLayouts, fetchLayout, selectLayout, updateLayoutName, clearLayout, shareLayout]);
};

export default useLayoutManager;
