import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';

const NoLayoutMessage = () => {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            width="100%"
        >
            <Box
                textAlign="center"
                maxWidth="600px"
                p={3}
            >
                <Typography variant="h5" gutterBottom sx={{ color: 'text.primary', mb: 2 }}>
                    No layout loaded
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary', mb: 4 }}>
                    Please upload or select a layout to get started.
                </Typography>
                <Button variant="contained" color="primary" onClick={handleGoHome} size="large">
                    Go to Home Page
                </Button>
            </Box>
        </Box>
    );
};

export default NoLayoutMessage;