import React from 'react';
import { Link } from 'react-router-dom';
import {
    AppBar as MuiAppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Box,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    Menu as MenuIcon,
    Brightness4,
    Brightness7,
} from '@mui/icons-material';
import logo from '../../assets/logo.png';

const StyledAppBar = styled(MuiAppBar)(({ theme }) => ({
    background: theme.palette.mode === 'dark' ? '#121212' : '#ffffff',
    color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
        display: 'none',
    },
}));

const NavItems = styled(Box)(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const StyledNavButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(0, 1),
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const AppBar = ({ user, onMenuToggle, onLogout, onLogin, darkMode, onToggleDarkMode }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <StyledAppBar position="fixed">
            <Toolbar>
                <MenuButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={onMenuToggle}
                >
                    <MenuIcon />
                </MenuButton>
                {!isMobile && (
                    <>
                        <img src={logo} alt="Logo" style={{ height: '32px', marginRight: '10px' }} />
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Companion
                        </Typography>
                    </>
                )}
                <NavItems>
                    <StyledNavButton color="inherit" component={Link} to="/">
                        Home
                    </StyledNavButton>
                    <StyledNavButton color="inherit" component={Link} to="/visualize">
                        Visualize
                    </StyledNavButton>
                    <StyledNavButton color="inherit" component={Link} to="/portmapping">
                        Port Mapping
                    </StyledNavButton>
                    <StyledNavButton color="inherit" component={Link} to="/report">
                        Report Issue
                    </StyledNavButton>
                </NavItems>
                <Box display="flex" alignItems="center" sx={{ marginLeft: 'auto' }}>
                    {!isMobile &&
                        (user ? (
                            <>
                                <Typography variant="body2" sx={{ marginRight: '20px' }}>
                                    {user.name}
                                </Typography>
                                <Button color="inherit" variant="outlined" onClick={onLogout} size="small">
                                    Logout
                                </Button>
                            </>
                        ) : (
                            <Button variant="contained" color="primary" onClick={onLogin}>
                                Sign in
                            </Button>
                        ))}
                    <IconButton
                        color="inherit"
                        onClick={onToggleDarkMode}
                        sx={{ ml: 2 }}
                        aria-label="toggle dark mode"
                    >
                        {darkMode ? <Brightness7 /> : <Brightness4 />}
                    </IconButton>
                </Box>
            </Toolbar>
        </StyledAppBar>
    );
};

export default AppBar;