import React from 'react';
import { Link } from 'react-router-dom';
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Box,
    styled,
} from '@mui/material';
import {
    Home as HomeIcon,
    ViewInAr as VisualizeIcon,
    SettingsInputComponent as PortMappingIcon,
    BugReport as ReportIcon,
} from '@mui/icons-material';

const MobileMenuItem = styled(ListItem)(({ theme }) => ({
    padding: theme.spacing(2),
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const MobileMenuIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: '40px',
    color: theme.palette.text.primary,
}));

const MobileMenu = ({ open, onClose }) => {
    return (
        <Drawer anchor="left" open={open} onClose={onClose}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <List sx={{ flexGrow: 1 }}>
                    <MobileMenuItem button component={Link} to="/" onClick={onClose}>
                        <MobileMenuIcon>
                            <HomeIcon />
                        </MobileMenuIcon>
                        <ListItemText primary="Home" />
                    </MobileMenuItem>
                    <MobileMenuItem button component={Link} to="/visualize" onClick={onClose}>
                        <MobileMenuIcon>
                            <VisualizeIcon />
                        </MobileMenuIcon>
                        <ListItemText primary="Visualize" />
                    </MobileMenuItem>
                    <MobileMenuItem button component={Link} to="/portmapping" onClick={onClose}>
                        <MobileMenuIcon>
                            <PortMappingIcon />
                        </MobileMenuIcon>
                        <ListItemText primary="Port Mapping" />
                    </MobileMenuItem>
                    <MobileMenuItem button component={Link} to="/report" onClick={onClose}>
                        <MobileMenuIcon>
                            <ReportIcon />
                        </MobileMenuIcon>
                        <ListItemText primary="Report Issue" />
                    </MobileMenuItem>
                </List>
            </Box>
        </Drawer>
    );
};

export default MobileMenu;