import React, { useState, useCallback, useMemo } from 'react';
import {
  List,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  IconButton,
  TextField,
  useMediaQuery,
  useTheme,
  SwipeableDrawer,
  Box,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import axios from 'axios';

const UserLayouts = React.memo(
  ({
    layouts,
    onLayoutSelect,
    onLayoutsChange,
    editingLayout,
    editingName,
    onEditLayout,
    onSaveLayoutName,
    onEditNameChange,
  }) => {
    const [swipeableDrawerOpen, setSwipeableDrawerOpen] = useState(false);
    const [selectedLayout, setSelectedLayout] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDelete = useCallback(
      async (layoutId) => {
        try {
          await axios.delete(`/api/layout/${layoutId}`);
          await onLayoutsChange(); // Ensure this is an async function
        } catch (error) {
          console.error('Error deleting layout:', error);
        }
      },
      [onLayoutsChange]
    );

    const handleSwipeableDrawerOpen = useCallback((layout) => {
      setSelectedLayout(layout);
      setSwipeableDrawerOpen(true);
    }, []);

    const handleSwipeableDrawerClose = useCallback(() => {
      setSwipeableDrawerOpen(false);
    }, []);

    const handleSaveLayoutName = useCallback(() => {
      onSaveLayoutName();
    }, [onSaveLayoutName]);

    const LayoutCard = useMemo(
      () =>
        React.memo(({ layout }) => {
          const isEditing = editingLayout && editingLayout._id === layout._id;

          return (
            <Card key={layout._id} sx={{ mb: 2 }}>
              <CardContent>
                {isEditing ? (
                  <TextField
                    value={editingName}
                    onChange={(e) => onEditNameChange(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleSaveLayoutName();
                      }
                    }}
                    placeholder="Enter layout name"
                    fullWidth
                    size="small"
                    autoFocus
                  />
                ) : (
                  <>
                    <Typography variant="h6">{layout.name}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Created: {new Date(layout.createdAt).toLocaleString()}
                    </Typography>
                  </>
                )}
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => onLayoutSelect(layout._id)}
                >
                  Load
                </Button>
                {isMobile ? (
                  <IconButton size="small" onClick={() => handleSwipeableDrawerOpen(layout)}>
                    <MoreVertIcon />
                  </IconButton>
                ) : (
                  <>
                    {isEditing ? (
                      <IconButton size="small" onClick={handleSaveLayoutName}>
                        <SaveIcon />
                      </IconButton>
                    ) : (
                      <IconButton size="small" onClick={() => onEditLayout(layout)}>
                        <EditIcon />
                      </IconButton>
                    )}
                    <IconButton size="small" onClick={() => handleDelete(layout._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </CardActions>
            </Card>
          );
        }),
      [
        editingLayout,
        editingName,
        isMobile,
        onEditLayout,
        handleSaveLayoutName,
        onEditNameChange,
        handleDelete,
        handleSwipeableDrawerOpen,
        onLayoutSelect,
      ]
    );

    const layoutsArray = Array.isArray(layouts) ? layouts : [];

    return (
      <Box>
        {layoutsArray.length === 0 ? (
          <Typography>You have no saved layouts yet.</Typography>
        ) : (
          <List>
            {layoutsArray.map((layout) => (
              <LayoutCard key={layout._id} layout={layout} />
            ))}
          </List>
        )}

        {isMobile && (
          <SwipeableDrawer
            anchor="bottom"
            open={swipeableDrawerOpen}
            onClose={handleSwipeableDrawerClose}
            onOpen={() => { }}
            disableSwipeToOpen={false}
            PaperProps={{
              style: {
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
              },
            }}
          >
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Layout Actions
              </Typography>
              {selectedLayout && (
                <>
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={<EditIcon />}
                    onClick={() => {
                      onEditLayout(selectedLayout);
                      handleSwipeableDrawerClose();
                    }}
                    sx={{ mb: 1 }}
                  >
                    Edit Name
                  </Button>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      handleDelete(selectedLayout._id);
                      handleSwipeableDrawerClose();
                    }}
                  >
                    Delete Layout
                  </Button>
                </>
              )}
            </Box>
          </SwipeableDrawer>
        )}
      </Box>
    );
  }
);

export default UserLayouts;
