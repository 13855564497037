import React from 'react';
import { Dialog, DialogTitle, DialogContent, Alert, Button, Divider, Typography } from '@mui/material';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import GoogleIcon from '@mui/icons-material/Google';

const AuthDialog = ({
    open,
    onClose,
    isLoginForm,
    setIsLoginForm,
    onLogin,
    onGoogleLogin,
    onRegister,
    authError,
    onForgotPassword,
}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{isLoginForm ? 'Sign In' : 'Sign Up'}</DialogTitle>
            <DialogContent>
                {authError && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {authError}
                    </Alert>
                )}
                {isLoginForm ? (
                    <LoginForm
                        onLogin={onLogin}
                        onToggleForm={() => setIsLoginForm(false)}
                        onForgotPassword={onForgotPassword}
                    />
                ) : (
                    <RegisterForm onRegister={onRegister} onToggleForm={() => setIsLoginForm(true)} />
                )}
                <Divider sx={{ my: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                        OR
                    </Typography>
                </Divider>
                <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<GoogleIcon />}
                    onClick={onGoogleLogin}
                    sx={{ mt: 1 }}
                >
                    Sign in with Google
                </Button>
            </DialogContent>
        </Dialog>
    );
};

export default AuthDialog;